<template>
  <div class="zoom-controls">
    <div>Zoom Control</div>
    <button class="btn btn-primary btn-sm rounded" @click="zoomOut">-</button>
    <span> {{ zoomLevel*100 }}%</span>
    <button class="btn btn-primary btn-sm rounded" @click="zoomIn">+</button>
  </div>
</template>
<script>
export default {
  name: 'site-zoom-control',
  data() {
    return {
      zoomLevel: 1, // Default zoom level
    }
  },
  methods: {
    zoomIn(event) {
      event.preventDefault();
      this.zoomLevel += 0.1;
      if (this.zoomLevel > 2) {
        this.zoomLevel = 2; // Set a maximum zoom limit
      } 

      this.applyZoom();
      localStorage.setItem('zoomLevel', this.zoomLevel);
    },
    zoomOut(event) {
      event.preventDefault();
      this.zoomLevel -= 0.1;
      if (this.zoomLevel < 0.5) this.zoomLevel = 0.5; // Set a minimum zoom limit
      this.applyZoom();
      localStorage.setItem('zoomLevel', this.zoomLevel);
    },
    applyZoom() {
      document.body.style.zoom = this.zoomLevel;
    },
    loadZoomLevel() {
      const savedZoomLevel = localStorage.getItem('zoomLevel');
      if (savedZoomLevel) {
        this.zoomLevel = parseFloat(savedZoomLevel);
        this.applyZoom();
      }
    },
    setInitialZoomBasedOnResolution(resetZoom = false) {
      if (!localStorage.getItem('zoomLevel')) {
        const screenWidth = window.screen.width;

        if (screenWidth < 1280) {
          this.zoomLevel = 0.9; // Slightly smaller for low-resolution screens
        } else if (screenWidth >= 1920) {
          this.zoomLevel = 1.1; // Slightly larger for high-resolution screens
        } else {
          this.zoomLevel = 1; // Default for mid-range resolutions
        }
        this.applyZoom();
        localStorage.setItem('zoomLevel', this.zoomLevel);
      }
    }
  },
  mounted() {
    this.setInitialZoomBasedOnResolution();
  }
}
</script>
<style lang="scss" scoped>
.site-zoom-control {
    margin: 0 30px;
}
.zoom-content-control {
  padding: 5px;
}
</style>