<template>
  <div class="edit-bulk-records">
    <div class="row">
      <div class="col-sm-12 col-lg-8">
        <h5>
          Records
          <i style="color: darkgrey" class="ti-angle-right small"></i>
          <router-link :to="{ path: query.ref }">
            {{ fromText }}
          </router-link>
          <i style="color: darkgrey" class="ti-angle-right small"></i>
            Bulk Sterilization
        </h5>
      </div>
    </div>
    <div class="row" v-if="selectRecordData.length">
      <div class="col-lg-12">
        <h6>Create exam & sterilization records for multiple animals at once</h6>
        <el-card
          class="box-card m-1 bulk-sterilization"
          shadow="hover"
          v-for="(record, index) of selectRecordData"
          v-bind:key="record.catch_release_details.id"
        >
          <div class="row" :class="record.saved">
            <div class="col-lg-6 border-right">
              <h6 class="">General</h6>
              <div class="row">
                <div class="col-lg-2">
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="record.catch_release_details.image_url"
                    :preview-src-list="[record.catch_release_details.image_url]"
                    lazy
                    alt="Animal Image"
                  >
                    <div slot="placeholder" class="image-slot">
                      Loading<span class="dot">...</span>
                    </div>
                  </el-image>
                </div>
                <div class="col-lg-5">
                  <div>
                    <label class="pr-1">HSIApps ID:</label>
                    <span>{{
                      record.catch_release_details.hsapps_id || "N/A"
                    }}</span>
                  </div>
                  <div>
                    <label class="pr-1">RFID:</label>
                    <span>{{
                      record.catch_release_details.rf_id || "N/A"
                    }}</span>
                  </div>
                  <div>
                    <label class="pr-1">Gender:</label>
                    <span>{{
                      record.catch_release_details.gender || "N/A"
                    }}</span>
                  </div>
                  <div>
                    <label class="pr-1">Ownership:</label>
                    <span>{{
                      record.catch_release_details.ownership || "N/A"
                    }}</span>
                  </div>
                </div>
                <div class="col-lg-5">
                  <div>
                    <label class="pr-1">Collar Id: </label>
                    <span>{{
                      record.catch_release_details.collar_id || "N/A"
                    }}</span>
                  </div>
                  <div>
                    <label class="pr-1">Caught Date: </label>
                    <span>{{
                      record.catch_release_details.caught_date || "N/A"
                    }}</span>
                  </div>
                  <div>
                    <a
                      :href="
                        'https://www.google.com/maps/search/?api=1&query=' +
                        record.catch_release_details.caught_location
                      "
                      target="_blank"
                    >
                      Caught Location
                      <i class="fa fa-external-link"></i>
                    </a>
                  </div>
                  <div>
                    <label class="pr-1">Caught Method:</label>
                    <span>{{
                      record.catch_release_details.caught_method || "N/A"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 pt-2 border-top">
                  <h6 class="">Pre-op Exam</h6>
                  <el-form
                    class="row mr-1"
                    :ref="'examForm' + record.catch_release_details.id"
                    :model="record.catch_release_details.exam_details"
                    label-width="80px"
                    label-position="top"
                  >
                    <div class="col-lg-4">
                      <el-form-item
                        label="Select Date"
                        prop="timestamp"
                         :rules="[
                          {
                            required: true,
                            message: 'Please select date time',
                            trigger: 'change',
                          },
                        ]"
                      >
                        <el-date-picker
                          v-model="
                            record.catch_release_details.exam_details.timestamp
                          "
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          placeholder="Select date and time"
                          @change="objectCheckBeforeSave(record, index)"
                        >
                        </el-date-picker>
                      </el-form-item>
                      <el-form-item label="Weight" prop="weight">
                        <el-input
                          placeholder=""
                          v-model="
                            record.catch_release_details.exam_details.weight
                          "
                          @change="objectCheckBeforeSave(record, index)"
                        >
                          <template slot="append">Kg</template>
                        </el-input>
                      </el-form-item>
                      <el-form-item label="Temperature" prop="temperature">
                        <el-input
                          placeholder=""
                          v-model="
                            record.catch_release_details.exam_details
                              .temperature
                          "
                          @change="objectCheckBeforeSave(record, index)"
                        >
                          <template slot="append">℉</template>
                        </el-input>
                      </el-form-item>
                      <el-form-item
                        label="Dehydration Level"
                        prop="dehydration_level"
                      >
                        <el-select
                          v-model="
                            record.catch_release_details.exam_details
                              .dehydration_level
                          "
                          @change="objectCheckBeforeSave(record, index)"
                          placeholder="Select"
                        >
                          <el-option
                            v-for="item in dehydrationLevels"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="col-lg-4">
                      <el-form-item
                        label="Select Veterinarian"
                        prop="vet_name"
                        :rules="[
                          {
                            required: true,
                            message: 'Please select veterinarian',
                            trigger: 'change',
                          },
                        ]"
                      >
                        <el-select
                          v-model="
                            record.catch_release_details.exam_details.vet_name
                          "
                          @change="objectCheckBeforeSave(record, index)"
                          placeholder="Select"
                        >
                            <el-option
                                style="color: deepskyblue"
                                key="-1"
                                label="+ Add New"
                                value="-1"
                            >
                            </el-option>
                          <el-option
                            v-for="item in vets"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        label="Heart Rate"
                        prop="heart_rate"
                        :rules="[
                          { validator: minMaxValidatorPerUnit}
                        ]">
                        <el-input
                          placeholder=""
                          v-model="
                            record.catch_release_details.exam_details.heart_rate
                          "
                          @change="objectCheckBeforeSave(record, index)"
                        >
                          <template slot="append">beats/min</template>
                        </el-input>
                      </el-form-item>
                      <el-form-item
                        label="Respiratory Rate"
                        prop="respiratory_rate"
                        :rules="[
                          { validator: minMaxValidatorPerUnit}
                        ]"
                      >
                        <el-input
                          placeholder=""
                          v-model="
                            record.catch_release_details.exam_details
                              .respiratory_rate
                          "
                          @change="objectCheckBeforeSave(record, index)"
                        >
                          <template slot="append">breaths/min</template>
                        </el-input>
                      </el-form-item>
                      <el-form-item
                        label="Body Condition Score"
                        prop="body_condition_score"
                      >
                        <el-select
                          v-model="
                            record.catch_release_details.exam_details
                              .body_condition_score
                          "
                          @change="objectCheckBeforeSave(record, index)"
                          placeholder="Select"
                        >
                          <el-option
                            v-for="item in bodyConditions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="col-lg-4">
                      <el-form-item
                        label="Mucus Membrane"
                        prop="mucus_membrane"
                      >
                        <el-select
                          v-model="
                            record.catch_release_details.exam_details
                              .mucus_membrane
                          "
                          @change="objectCheckBeforeSave(record, index)"
                          placeholder="Select"
                        >
                          <el-option
                            v-for="item in mucusMembraneList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="Mentation" prop="mentation">
                        <el-select
                          v-model="
                            record.catch_release_details.exam_details.mentation
                          "
                          @change="objectCheckBeforeSave(record, index)"
                          placeholder="Select"
                        >
                          <el-option
                            v-for="item in mentationList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        v-if="record.catch_release_details.gender.toLowerCase() === 'female'"
                        label="Reproductive Status"
                        prop="reproductive_status"
                      >
                        <el-select
                          v-model="
                            record.catch_release_details.exam_details
                              .reproductive_status
                          "
                          @change="objectCheckBeforeSave(record, index)"
                          placeholder="Select"
                        >
                          <el-option
                            v-for="item in reproductiveStatusList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="Select Conditions" prop="condition">
                        <el-select
                          v-model="
                            record.catch_release_details.exam_details.condition
                          "
                          @change="objectCheckBeforeSave(record, index)"
                          multiple
                          placeholder="Multi Select"
                        >
                          <el-option
                            v-for="item in conditionsList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </el-form>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="row border-bottom">
                <div class="col-lg-12">
                  <h6>Sterilization</h6>
                  <el-form
                    class="row mr-1"
                    :ref="'sterlizationForm' + record.catch_release_details.id"
                    :model="record.catch_release_details.sterilization_details"
                    label-width="80px"
                    label-position="top"
                  >
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-lg-12 d-flex flex-row">
                          <el-checkbox
                            v-if="record.catch_release_details.gender.toLowerCase() === 'female'"
                            class="mr-3"
                            v-model="
                              record.catch_release_details.sterilization_details
                                .pregnant
                            "
                            @change="
                              resetPregnantFields(
                                record.catch_release_details
                                  .sterilization_details
                              )
                            "
                            >Pregnant</el-checkbox
                          >

                          <el-form-item
                            v-if="record.catch_release_details.gender.toLowerCase() === 'female'"
                            class="mr-3"
                            label="Fetus Count"
                            prop="fetus_count"
                            :rules="[
                              {
                                required:
                                  record.catch_release_details
                                    .sterilization_details.pregnant,
                                message: 'Fetus Count is required',
                              },
                            ]"
                          >
                            <el-select
                              v-bind:disabled="
                                !record.catch_release_details
                                  .sterilization_details.pregnant
                              "
                              v-model="
                                record.catch_release_details
                                  .sterilization_details.fetus_count
                              "
                              @change="objectCheckBeforeSave(record, index)"
                              placeholder="Fetus Count"
                            >
                              <el-option
                                v-for="item in topUps"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item
                            v-if="record.catch_release_details.gender.toLowerCase() === 'female'"
                            class="mr-3"
                            label="Pregnancy Stage"
                            prop="pregnancy_stage"
                            :rules="[
                              {
                                required:
                                  record.catch_release_details
                                    .sterilization_details.pregnant,
                                message: 'Pregnancy Stage is required',
                              },
                            ]"
                          >
                            <el-select
                              v-bind:disabled="
                                !record.catch_release_details
                                  .sterilization_details.pregnant
                              "
                              v-model="
                                record.catch_release_details
                                  .sterilization_details.pregnancy_stage
                              "
                              @change="objectCheckBeforeSave(record, index)"
                              placeholder="Select"
                            >
                              <el-option
                                v-for="item in pregnancyStages"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <el-form-item
                            label="Select Conditions"
                            prop="conditions"
                          >
                            <el-select
                              v-model="
                                record.catch_release_details
                                  .sterilization_details.conditions
                              "
                              @change="objectCheckBeforeSave(record, index)"
                              multiple
                              placeholder="Multi Select"
                            >
                              <el-option
                                v-for="item in sterlizationConditions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                        <div class="col-lg-3">
                          <el-form-item
                            label="Number of Top-ups"
                            prop="top_ups"
                          >
                            <el-select
                              v-model="
                                record.catch_release_details
                                  .sterilization_details.top_ups
                              "
                              @change="objectCheckBeforeSave(record, index)"
                              placeholder="Select"
                            >
                              <el-option
                                v-for="item in topUps"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                        <div class="col-lg-3">
                          <el-form-item
                            label="Sterilization Result"
                            prop="operation_result"
                            :rules="[
                              {
                                required: true,
                                message: 'Sterlization Result is required',
                              },
                            ]"
                          >
                            <el-select
                              v-model="
                                record.catch_release_details
                                  .sterilization_details.operation_result
                              "
                              @change="onSterlizationResultChange(record, index)"
                              placeholder="Select"
                            >
                              <el-option
                                v-for="item in sterlizationResults"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                        <div class="col-lg-3">
                          <el-form-item
                            label="Surgery Duration"
                            prop="duration"
                            :rules="[
                              {
                                required: true,
                                message: 'Surgery Duration is required',
                              },
                              { validator: minMaxValidatorPerUnit },
                            ]"
                          >
                            <el-input
                              type="number"
                              v-model="
                                record.catch_release_details
                                  .sterilization_details.duration
                              "
                              @change="objectCheckBeforeSave(record, index)"
                            >
                              <template slot="append">mins</template>
                            </el-input>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <el-form-item
                            label="Complications"
                            prop="complications"
                          >
                            <el-select
                              v-model="
                                record.catch_release_details
                                  .sterilization_details.complications
                              "
                              multiple
                              @change="objectCheckBeforeSave(record, index)"
                              placeholder="Select"
                            >
                              <el-option
                                v-for="item in complications"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                        <div class="col-lg-3">
                          <el-form-item
                            label="Anaesthesia Given"
                            prop="anesthesia_given"
                            :rules="[{ validator: minMaxValidatorPerUnit }]"
                          >
                            <el-input
                              type="number"
                              placeholder=""
                              v-model="
                                record.catch_release_details
                                  .sterilization_details.anesthesia_given
                              "
                              @change="objectCheckBeforeSave(record, index)"
                            >
                              <template slot="append">ml</template>
                            </el-input>
                          </el-form-item>
                        </div>
                        <div class="col-lg-3">
                          <el-form-item
                            label="Release Date"
                            prop="release_date"
                            :rules="[
                              {
                                required: (record.catch_release_details.exam_details.next_step === 'Cleared for Release'&&record.catch_release_details.release_date==null),
                                message: 'Release date is required',
                              }
                            ]"
                          >
                            <el-date-picker
                              v-bind:disabled="record.catch_release_details.exam_details.next_step === 'Put on Post-op hold'"
                              v-model="
                                record.catch_release_details.release_date
                              "
                              type="date"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="Select date"
                              @change="validateReleaseDate(record, index)"
                            >
                            </el-date-picker>
                          </el-form-item>
                        </div>
                        <div class="col-lg-3">
                          <el-form-item prop="rabiesVaccinationGiven" class="m-0">
                            <span slot="label">
                              Rabies Vaccination?
                              <el-tooltip content="Reset" placement="top">
                                <i
                                  class="
                                    el-icon-refresh
                                    reset-rabies-vaccination
                                  "
                                  @click="
                                    resetRabiesVaccination(
                                      record.catch_release_details
                                        .sterilization_details
                                    )
                                  "
                                ></i>
                              </el-tooltip>
                            </span>
                            <el-radio-group
                              class="rabies-vaccination-given"
                              v-model="
                                record.catch_release_details
                                  .sterilization_details.rabies_vaccine
                              "
                              @change="objectCheckBeforeSave(record, index)"
                            >
                              <el-radio
                                label="Vaccination Given"
                                value="Vaccination Given"
                              ></el-radio>
                              <el-radio
                                label="Already Vaccinated"
                                value="Already Vaccinated"
                              ></el-radio>
                            </el-radio-group>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="row">
                        <div
                          v-if="
                            record.catch_release_details.sterilization_details?
                            record.catch_release_details.sterilization_details.complications?
                            record.catch_release_details.sterilization_details.complications.includes('Other'):false:false
                          "
                          class="col-lg-3"
                        >
                          <template>
                            <el-form-item
                              label="Complication Note"
                              prop="complication_other_note"
                            >
                              <el-input
                                type="textarea"
                                :rows="1"
                                placeholder="Please input"
                                v-model="
                                  record.catch_release_details
                                    .sterilization_details
                                    .complication_other_note
                                "
                                @change="objectCheckBeforeSave(record, index)"
                              >
                              </el-input>
                            </el-form-item>
                          </template>
                        </div>
                        <div class="col">
                          <el-form-item
                            label="Select Date"
                            prop="timestamp"
                            :rules="[
                              {
                                required: true,
                                message: 'Please select date time',
                                trigger: 'change',
                              },
                            ]"
                          >
                            <el-date-picker
                              v-model="
                                record.catch_release_details.sterilization_details.timestamp
                              "
                              type="datetime"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="Select date and time"
                              @change="objectCheckBeforeSave(record, index)"
                            >
                            </el-date-picker>
                          </el-form-item>
                        </div>
                        <div class="col">
                          <el-form-item
                            label="Select Veterinarian"
                            prop="vet_name"
                            :rules="[
                              {
                                required: true,
                                message: 'Please select veterinarian',
                                trigger: 'change',
                              },
                            ]"
                          >
                            <el-select
                              v-model="
                                record.catch_release_details.sterilization_details.vet_name
                              "
                              @change="objectCheckBeforeSave(record, index)"
                              placeholder="Select"
                            >
                                <el-option
                                    style="color: deepskyblue"
                                    key="-1"
                                    label="+ Add New"
                                    value="-1"
                                >
                                </el-option>
                              <el-option
                                v-for="item in vets"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                        <div class="col">
                          <el-form-item label="Note" prop="note">
                            <el-input
                              type="textarea"
                              :rows="1"
                              placeholder="Please input"
                              v-model="
                                record.catch_release_details
                                  .sterilization_details.note
                              "
                              @change="objectCheckBeforeSave(record, index)"
                            >
                            </el-input>
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                  </el-form>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-6">
                  <h6>Treatment</h6>
                  <el-select
                    v-model="
                      record.catch_release_details.treatment_details
                        .vaccinations_given
                    "
                    @change="objectCheckBeforeSave(record, index)"
                    multiple
                    placeholder="Select Vaccines Given"
                  >
                    <el-option
                      v-for="item in vaccines"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="col-lg-6">
                  <el-form
                    :ref="'nextStepForm' + record.catch_release_details.id"
                    :model="record.catch_release_details.exam_details"
                    label-width="80px"
                    label-position="top"
                    class="mr-2">
                    <el-form-item
                      label="Next Step"
                      prop="next_step"
                      :rules="[
                        {
                          required: true,
                          message: 'Next step is required',
                        }
                      ]">
                      <el-select
                        v-model="
                          record.catch_release_details.exam_details.next_step
                        "
                        @change="validateReleaseDate(record, index)"
                        placeholder="Next Step"
                      >
                        <el-option
                          v-for="item in nextStepList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <div class="float-right save-btn d-flex">
                  <el-alert
                      v-if="(record.catch_release_details.sterilization_details.operation_result==='Sterilization Successful')
                          &&
                          (record.catch_release_details.exam_details.next_step!=='Put on Post-op hold' &&
                           record.catch_release_details.release_date === null)"
                      class="mr-3"
                      title="Release date or next step post-op hold required if sterilization is successful"
                      type="danger"
                      :closable="false"
                      show-icon>
                  </el-alert>
                <el-alert
                  v-if="record.isSuccessfullySaved === true || record.isSuccessfullySaved === false"
                  class="mr-3"
                  :title="record.isSuccessfullySaved ? 'Data saved' : 'Data not saved'"
                  :type="record.isSuccessfullySaved ? 'success' : 'danger'"
                  :closable="false"
                  show-icon>
                </el-alert>
                <button
                  type="button"
                  @click="saveRecord(record)"
                  class="btn btn-success"
                  :disabled="record.saveBtnDisable"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Card,
  Row,
  Col,
  Image,
  Form,
  FormItem,
  DatePicker,
  Input,
  Select,
  Option,
  Checkbox,
  Radio,
  RadioGroup,
  Tooltip,
  Alert
} from "element-ui";
import { formModels, staticData } from "../../../constant";
import _ from 'lodash';
import Swal from 'sweetalert2';
export default {
  components: {
    [Card.name]: Card,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [DatePicker.name]: DatePicker,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Tooltip.name]: Tooltip,
    [Alert.name]: Alert,
  },
  data() {
    return {
      isLoadingData: true,

        fromText: "",
      selectRecordData: [],
      clonedRecordData: [],
      vets: [],
      vaccines: [],
      givenVaccines: [],
      dehydrationLevels: staticData.dehydrationLevels,
      bodyConditions: staticData.bodyConditions,
      mucusMembraneList: staticData.mucusMembraneList,
      mentationList: staticData.mentationList,
      reproductiveStatusList: staticData.reproductiveStatusList,
      conditionsList: staticData.conditionsList,
      nextStepList: staticData.nextStepList.filter((step) => step.value !== 'Cleared for Sterilization'),
      topUps: staticData.topUps,
      pregnancyStages: staticData.pregnancyStages,
      sterlizationConditions: staticData.sterlizationConditions,
      complications: staticData.complications,
      sterlizationResults: staticData.sterlizationResults,
      defaultExamDetails: {
        timestamp: null,
        weight: null,
        temperature: null,
        dehydration_level: null,
        vet_name: null,
        heart_rate: null,
        respiratory_rate: null,
        body_condition_score: null,
        mucus_membrane: null,
        mentation: null,
        reproductive_status: null,
        condition: [],
        next_step: null,
      },
      defaultSterilizationDetails: {
        fetus_count: null,
        pregnancy_stage: null,
        rabies_vaccine: null,
        conditions: [],
        top_ups: null,
        operation_result: null,
        duration: null,
        complications: null,
        anesthesia_given: null,
        postOpHold: null,
        release_date: null,
        note: null,
      },
      defaultTreatmentDetails: {
        vaccinations_given: [],
      },
    };
  },
  props: {
    query: {
      type: Object,
    },
  },
  beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    const leaveConfirm = window.confirm(
      "Are you sure you want to leave?"
    );
    if (leaveConfirm) {
      next();
    } else {
      // Navigate to next view
      next(false);
    }
  },

  created() {
    // TODO: add event listener if form value change
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  methods: {
    beforeWindowUnload(e) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = "";
    },
    init(selectedIDs) {
      this.getRecordDetails(selectedIDs);
      this.getVets();
      this.getVaccines();
    },
    getRecordDetails(selectedIDs) {
      const vm = this;
      axios
        .get("catch_release/bulk/record/details", {
          params: {
            record_ids: selectedIDs,
            project_id: vm.$store.getters.getCurrentSelectedProject.id,
          },
        })
        .then((response) => {
          if (response.data.response.error.error_code === 0) {
            const data = response.data.response.data.bulk_record_details.map(
              (record) => {
                const details = record.catch_release_details;
                if (!details.exam_details) {
                  details.exam_details = _.cloneDeep(this.defaultExamDetails);
                }
                if (!details.sterilization_details) {
                  details.sterilization_details =
                    _.cloneDeep(this.defaultSterilizationDetails);
                }
                if (!details.treatment_details) {
                  details.treatment_details = _.cloneDeep(this.defaultTreatmentDetails);
                }
                const sterilization_details = _.cloneDeep(details.sterilization_details);

                if (
                  sterilization_details.fetus_count &&
                  sterilization_details.pregnancy_stage
                ) {
                  sterilization_details["pregnant"] = true;
                }

                record['saveBtnDisable'] = false;
                record['saved'] = '';
                record['isSuccessfullySaved'] = null;
                return record;
              }
            );
            this.clonedRecordData = _.cloneDeep(data);
            this.selectRecordData = data;
          }
        })
        .catch((error) => {
          vm.showAlert(
            "Error",
            error.response.data.response.error.error_msg,
            false
          );
        });
    },
    getVets() {
      var vm = this;
      axios
        .get("/organisation/get/vets", {
          params: {
            organisation_id:
              vm.$store.getters.getCurrentSelectedProject.organisation_id,
          },
        })
        .then((response) => {
          if (response.data.response.error.error_code == 0) {
            vm.vets = [];
            var v = response.data.response.data.vets;
            for (let i = 0; i < v.length; i++) {
              vm.vets.push({
                value: v[i].first_name + " " + v[i].last_name,
                label: v[i].first_name + " " + v[i].last_name,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVaccines() {
      var vm = this;
      axios
        .get(
          "/vaccination_drug/get_all/" +
            vm.$store.getters.getCurrentSelectedProject.organisation_id
        )
        .then((response) => {
          if (response.data.response.error.error_code == 0) {
            vm.vaccines = [];
            var v = response.data.response.data.vaccination_drugs;
            for (let i = 0; i < v.length; i++) {
              vm.vaccines.push({
                value: v[i].vaccination_drug_name,
                label: v[i].vaccination_drug_name,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetPregnantFields(record) {
      if (!record.pregnant) {
        record.fetus_count = "";
        record.pregnancy_stage = "";
      }
    },
    resetRabiesVaccination(record) {
      record.rabies_vaccine = false;
    },
    minMaxValidatorPerUnit(rule, value, callback) {
      if (value < 0 || value > 999) {
        callback(new Error('must be between 0 to 999'));
      } else {
        callback();
      }
    },
    validateReleaseDate(record, index) {
      const sterlizationForm = this.$refs["sterlizationForm" + record.catch_release_details.id][0];
      if (record.catch_release_details.exam_details.next_step === 'Put on Post-op hold') {
        record.catch_release_details.sterilization_details.release_date = null;
      }
      setTimeout(() => {
        sterlizationForm.validate();
        this.objectCheckBeforeSave(record, index);
      });
    },
    onSterlizationResultChange(record, index) {
      const excludeData = ['Cleared for Sterilization'];
      const selectedResult = record.catch_release_details.sterilization_details.operation_result;
      if (selectedResult === 'Sterilization Successful') {
        excludeData.push('Put on Pre-op hold', 'Sterilization Aborted');
      }
      this.nextStepList = staticData.nextStepList.filter((step) => !excludeData.includes(step.value)),
      this.objectCheckBeforeSave(record, index);
    },
    saveRecord(record) {
      let isValidForms = [];
      const vm = this;
      const forms = [
        this.$refs["examForm" + record.catch_release_details.id][0],
        this.$refs["sterlizationForm" + record.catch_release_details.id][0],
        this.$refs["nextStepForm" + record.catch_release_details.id][0],
      ];
      forms.forEach((form) => {
        form.validate((valid) => {
          isValidForms.push(valid);
        });
      });

      if(record.catch_release_details
          .sterilization_details.operation_result==='Sterilization Successful'){
          if(record.catch_release_details.exam_details.next_step!=='Put on Post-op hold' && record.catch_release_details.release_date === null){
              return
          }
      }


      if (isValidForms.every((form) => form)) {
        const {saveBtnDisable, saved, ...finalRecord} = record;
        axios
          .post(
            `/catch_release/save/record/details?record_id=${record.catch_release_details.id}&project_id=${vm.$store.getters.getCurrentSelectedProject.id}`,
            { record: finalRecord }
          )
          .then((response) => {
            record.saveBtnDisable = false;
            if (response.data.response.error.error_code == 0) {
              record.catch_release_details = response.data.response.data.record_details;
              this.hightLightSavedRecord(record, 'highlight-saved-success', true);
            } else {
              this.hightLightSavedRecord(record, 'highlight-saved-failed', false);
            }
          })
          .catch((error) => {
            record.saveBtnDisable = false;
            this.hightLightSavedRecord(record, 'highlight-saved-failed', false);
          });
      }
    },
    objectCheckBeforeSave(record, index) {
        if(index===0){
            if(record.catch_release_details.exam_details.vet_name==="-1"){
                record.catch_release_details.exam_details.vet_name=null
                window.open('/platform/projects?type=organisation&selected_tab=2','_blank')
                return
            }
        }
      record.saveBtnDisable = true;
      const isDataEqual = deepEqual(this.clonedRecordData[index], record);
      if (!isDataEqual) {
        this.saveRecord(record);
      } else {
        record.saveBtnDisable = false;
      }
    },
    hightLightSavedRecord(record, className, isSuccessfullySaved) {
      record.saved = className;
      record.isSuccessfullySaved = isSuccessfullySaved;
      setTimeout(() => {
        record.saved = '';
      },400);

      setTimeout(() => {
        record.isSuccessfullySaved = null;
      },1200);
    },
    showZoomInfoDialog() {
      new Swal({
        icon: 'info',
        title: 'Cannot see things properly on your screen?',
        text: `Use can use zoom control from the User Profile menu.`,
        type: 'info',
        confirmButtonClass: 'btn btn-success btn-fill',
        buttonsStyling: false,
      })
    },
  },
  mounted() {

    this.$sidebar.toggleMinimize();
    this.showZoomInfoDialog();
    this.fromText = this.query.fromText;
    const selectedIDs = JSON.parse(this.query.selectedRecordIds);
    this.init(selectedIDs);
  },
};
function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }
  return true;
}
function isObject(object) {
  return object != null && typeof object === 'object';
}
</script>
<style lang="scss">
.edit-bulk-records {
  .el-form-item__label {
    padding: 0 !important;
    margin-bottom: 5px !important;
    line-height: 10px !important;
  }
}
.rabies-vaccination-given {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  .el-radio {
    display: flex;
    margin-left: 0;
    padding-bottom: 10px;
  }
}

@keyframes blinkingSuccess {
	0%,
	50% {
		opacity: 1;
    background-color: #00b749dc;
	}

	100% {
		opacity: 0;
	}
}

@keyframes blinkingFailed {
	0%,
	50% {
		opacity: 1;
    background-color: #FF3333;
	}

	100% {
		opacity: 0;
	}
}

.highlight-saved-success {
  animation: blinkingSuccess 0.5s cubic-bezier(0.83, 0, 0.17, 1) 0s 1 normal forwards;
}

.highlight-saved-failed {
  animation: blinkingFailed 0.5s cubic-bezier(0.83, 0, 0.17, 1) 0s 1 normal forwards;
}

.bulk-sterilization {
  .el-card__body {
    padding: 10px;
  }
}

.save-btn {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 15px;
  bottom: 0;
}

</style>