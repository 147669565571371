export const SET_DEMO_DATA = "SET_DEMO_DATA";
export const APPEND_DEMO_DATA = "APPEND_DEMO_DATA";
export const SET_SELECTED_DATA = "SET_SELECTED_DATA";

export const SET_USER_PROJECTS = "SET_USER_PROJECTS";
export const ADD_PROJECT = "ADD_PROJECT";

export const SET_ANIMALS = "SET_ANIMALS";

export const SET_USER_ROLES = "SET_USER_ROLES";

export const SET_ORGANISATION = "SET_ORGANISATION";

export const SET_USER_ORGANISATIONS = "SET_USER_ORGANISATIONS";

export const SET_VACCINATION_DRUG = "SET_VACCINATION_DRUG";
export const SET_SPECIES = "SET_SPECIES";

export const SET_SELECTED_PROJECT_ID = "SET_SELECTED_PROJECT_ID";

export const SET_JWT_TOKEN = "SET_JWT_TOKEN";

export const SET_AUTHENTICATION_ERROR = "SET_AUTHENTICATION_ERROR";
export const SET_PENDING_ORG_REQ = "SET_PENDING_ORG_REQ";
export const UNSET_PENDING_ORG_REQ = "UNSET_PENDING_ORG_REQ";

export const SET_SUPER_ADMIN = "SET_SUPER_ADMIN";
export const UNSET_SUPER_ADMIN = "UNSET_SUPER_ADMIN";

export const UNSET_JWT_TOKEN = "UNSET_JWT_TOKEN";

export const SET_PROJECT_NAME = "SET_PROJECT_NAME";
export const SET_PROJECT_TYPE = "SET_PROJECT_TYPE";
export const SET_SCREEN_NAME = "SET_SCREEN_NAME";
export const SET_ORGANISATION_NAME = "SET_ORGANISATION_NAME";
export const SET_ORGANISATION_LOGO = "SET_ORGANISATION_LOGO";

export const SET_CURRENT_SELECTED_PROJECT = "SET_CURRENT_SELECTED_PROJECT";

export const SET_USER_PROJECT_COUNT = 'SET_USER_PROJECT_COUNT';

export const SET_USER_DATA = 'SET_USER_DATA';

export const SET_MAINTENANCE_DETAILS = 'SET_MAINTENANCE_DETAILS';

export const SET_TREATMENTS = "SET_TREATMENTS";

export const SET_DEWORMING = "SET_DEWORMING";