<template>
    <div>
<!--        <h6>{{ title }}</h6>-->
<!--        <hr>-->
        <div class="row text-center">
            <div v-for="(value, key) in reportSummary" :key="key" class="col-md-3 col-sm-6 col-12">
                <div class="card setShadow">
                    <div class="card-body" style="padding: 10px;">
                        <span>{{ key }}</span>
                        <h5>{{ value }}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card setShadow">
                    <div class="card-body" style="padding: 0;">
                        <h6 style="margin: 10px">Project Locations & Data Points</h6>
                        <div :id="mapRefId" style="width: 100%; height: 500px;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        report: Object,
        kmlUrls: Array,
        mapRefId: String,
        initMap: Function
    },
    computed: {
        reportSummary() {
            const summary = {
                "Total Projects": this.report.total_projects,
                "Total Male": this.report.total_male,
                "Total Female": this.report.total_female,
                "Total Areas": this.report.total_areas,
            };

            if (this.title === "Population Management (spay neuter)") {
                summary["Total Caught"] = this.report.total_catching;
                summary["Total Sterilized"] = this.report.total_sterilized;
                summary["Avg Sterilization Time"] = this.report.avg_sterilization_time;
                summary["Mortality Percentage"] = `${this.report.mortality_percentage}%`;
            }

            if (this.title === "Mass Vaccination") {
                summary["Total Vaccinations"] = this.report.total_vaccinations;
                summary["Areas Surveyed"] = this.report.area_surveyed;
                summary["Avg Treated/Day"] = this.report.avg_treated_per_day;
                summary["Vacc. Rate Based on Surveys"] = this.report.vaccination_rate_based_on_survey;
            }

            if (this.title === "Health Clinic") {
                return {
                    "Total Dewormings": this.report.total_dewormings,
                    "Total Female": this.report.total_female,
                    "Total Male": this.report.total_male,
                    "Total Not Owned": this.report.total_not_owned,
                    "Total Owned": this.report.total_owned,
                    "Total Sterilized": this.report.total_sterilized,
                    "Total Treatments": this.report.total_treatments,
                    "Total Unsterilized": this.report.total_unsterilized
                }
            }

            return summary;
        }
    },
    mounted() {
        this.initMap();
    }
}
</script>

<style scoped>
/* Add your component-specific styles here if needed */
</style>
