<template>

    <div>
        <fieldset>
            <div class="form-group">
                <div class="col-sm-12 col-md-4">
                    <fg-input type="text" label="Name*" name="projectName" v-validate="modelValidations.projectName"
                        :error="getError('projectName')" v-model="model.projectName">
                    </fg-input>
                </div>
            </div>
        </fieldset>
        <br />
        <fieldset>
            <div class="form-group">
                <label class="col-sm-12 control-label">Description</label>
                <div class="col-sm-8">
                    <textarea v-model="model.projectDescription" required class="form-control" rows="3"></textarea>
                </div>
            </div>
        </fieldset>
        <br>
        <fieldset>
            <div class="form-group">
                <label class="col-sm-12 control-label">Banner Message (Optional)</label>
                <div class="col-sm-8">
                    <textarea
                        placeholder="This message will appear on the top of the project overview screen. You can use it for reminders or notes to yourself or other users."
                        v-model="model.projectBannerMessage" required class="form-control" rows="3"></textarea>
                </div>
            </div>
        </fieldset>
        <br />
        <div class="col-sm-12">
            <div class="row">
                <label class="col-sm-12 control-label">Visibility</label>
                <div class="col-sm-3">
                    <template>
                        <p-radio :checked="model.projectAssociate == '1'" v-model="model.projectAssociate" label="1"
                            @change="onVisibilityChange">
                            Private
                        </p-radio>
                        <p-radio :checked="model.projectAssociate == '2'" v-model="model.projectAssociate" label="2">
                            Entire Organisation
                        </p-radio>
                    </template>
                </div>


                <template v-if="model.projectAssociate == '2' && userOrganisations.length == 0">
                    <div class="col-sm-4">
                        <p>
                            You have not yet applied to be an Organisation. Like Teams, Organisations enable
                            collaboration
                            features allowing you to add others as administrators or field users to your projects.<a
                                href="/platform/user/organisation"> Apply Now</a>
                        </p>
                    </div>
                </template>
                <template v-else>
                    <div class="col-sm-5">
                        <p>{{ projectAssociateInfo }}</p>
                    </div>
                </template>
            </div>
        </div>
        <br />

        <fieldset>
            <div class="form-group">
                <label class="col-sm-12 control-label">Ownership</label>

                <div class="col-sm-12">
                    <el-checkbox-group v-model="model.selectedCategories">
                        <el-checkbox v-for="ownership in ownerships" :key="ownership" :label="ownership">{{ ownership
                            }}</el-checkbox>
                    </el-checkbox-group>
                </div>

            </div>
        </fieldset>
        <br />

        <template v-if="model.projectAssociate == '2' && userOrganisations.length > 0">
            <fieldset>
                <div class="form-group">
                    <label class="col-sm-12 control-label">Species</label>
                    <div class="col-sm-12">
                        <el-checkbox-group v-model="model.selectedAnimals">
                            <el-checkbox v-for="(animal, key) in orgAnimals" :label="animal.species_name"
                                :key="'defaultAnimal' + key">
                                {{ animal.species_name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
            </fieldset>
            <div class="col-sm-4">
                <template>
                    <a @click="showManageItemsModal('species')" style="color: #51cbce;">Manage</a>
                </template>
            </div>
        </template>
        <template v-else>
            <fieldset>
                <div class="form-group">
                    <label class="col-sm-12 control-label">Species</label>
                    <div class="col-sm-12">
                        <el-checkbox-group v-model="model.selectedAnimals">
                            <el-checkbox label="1">DOG</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
            </fieldset>
        </template>
        <br />
        <fieldset>
            <div class="form-group">
                <label class="col-sm-12 control-label">Vaccine/Drug</label>
                <div class="col-sm-10">
                    <el-checkbox-group v-model="model.vaccinationDrug">
                        <el-checkbox v-for="(vaccination, key) in vaccinationDrug" :label="vaccination.id"
                            :key="'defaultVaccine' + key">
                            {{ vaccination.vaccination_drug }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
        </fieldset>
        <div class="col-sm-4">
            <template>
                <a @click="showManageItemsModal('vaccine')" style="color: #51cbce;">Manage</a>
            </template>
        </div>

        <br>

        <fieldset>
            <div class="form-group">
                <label class="col-sm-12 control-label">Vaccination Survey Percentage
                    <br>(The target percentage to consider vaccination efforts a success.)</label>
                <div class="col-sm-6 col-lg-2 col-md-4">
                    <fg-input v-model="model.surveyPercentage" type="text" name="Survey Percentage"
                        v-validate="modelValidations.surveyPercentage" :error="getError('Survey Percentage')"
                        placeholder="Set Survey Percentage..."></fg-input>
                </div>
            </div>
        </fieldset>

        <br />
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-12">
                    <p>Project Location</p>
                    <div class="card">
                        <div class="card-header">
                            <p class="card-title"><b>(Search for your city/region and then pin it
                                    on the map using your cursor)</b></p>
                        </div>
                        <div class="card-body">
                            <div class="pac-card" id="pac-card">
                                <div>
                                    <div id="title">
                                        Search Locations
                                    </div>
                                    <div id="type-selector" class="pac-controls">
                                        <input type="radio" name="type" id="changetype-all" checked="checked">
                                        <label for="changetype-all">All</label>
                                        <input type="radio" name="type" id="changetype-establishment">
                                        <label for="changetype-establishment">Establishments</label>
                                        <input type="radio" name="type" id="changetype-address">
                                        <label for="changetype-address">Addresses</label>
                                    </div>
                                </div>
                                <div id="pac-container">
                                    <input id="pac-input" type="text" placeholder="Enter a location">
                                </div>
                            </div>
                            <div id="regularMap" class="map"></div>
                            <div id="infowindow-content">
                                <img src="" width="16" height="16" id="place-icon">
                                <span id="place-name" class="title"></span><br>
                                <span id="place-address"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <p>
                        To load Country, State and Province please select location pin point on Google Maps.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <label class="control-label">Country</label>
                    <br />
                    <el-select :disabled="fetchingAddress" class="select-info" size="large"
                        :placeholder="selectCountryLabel" v-model="model.selectedCountry">
                        <el-option v-for="country in googleCountries" class="select-danger" :value="country"
                            :label="country" :key="country">
                        </el-option>
                    </el-select>
                </div>
                <div class="col-lg-4">
                    <label class="control-label">State/Province</label>
                    <br />
                    <el-select :disabled="fetchingAddress" class="select-info" size="large"
                        :placeholder="selectStateLabel" v-model="model.selectedState">
                        <el-option v-for="state in googleStates" class="select-danger" :value="state" :label="state"
                            :key="state">
                        </el-option>
                    </el-select>
                </div>
                <div class="col-lg-4">
                    <label class="control-label">City/District/Village</label>
                    <br />
                    <el-select :disabled="fetchingAddress" class="select-info" size="large"
                        :placeholder="selectLocalityLabel" v-model="model.selectedLocality">
                        <el-option v-for="locality in googleLocality" class="select-danger" :value="locality"
                            :label="locality" :key="locality">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <br />
        <br />
        <label class="col-sm-12 control-label" style="display: none">Project Date Format</label>
        <div class="col-sm-2" style="display: none">
            <el-select class="select-info" size="large" placeholder="Single Select" v-model="model.selectedDateFormat">
                <el-option v-for="dateFormat in model.dateFormats" class="select-danger" :value="dateFormat"
                    :label="dateFormat" :key="dateFormat">
                </el-option>
            </el-select>
        </div>
        <br />
        <label class="col-sm-12 control-label" style="display: none">Project Time Zone</label>
        <div class="col-sm-12 col-md-4" style="display: none">
            <el-select class="select-info" size="large" placeholder="Single Select" v-model="model.selectedTimezone">
                <el-option v-for="timezone in timezones" class="select-danger" :value="timezone.label"
                    :label="timezone.label" :key="timezone.label">
                </el-option>
            </el-select>
        </div>
        <br />
        <div class="col-sm-10" style="display: none">
            <p>Select Project Image ({{ uploadingLabel }})</p>
            <image-upload :target="imageUploadApiUrl" selector="example" :auto-push="true">
            </image-upload>
            <br />
        </div>
        <div class="col-md-12" style="text-align: center !important;">

            <button @click="cancleNewProject('warning-message-and-confirmation')" type="button"
                class="btn btn-round btn-danger">Cancel
            </button>

            <button v-if="onLine" v-bind:disabled="isDisabled" v-on:click="validateAndSubmit" type="button"
                class="btn btn-round btn-info">Create MV Project
            </button>
            <button v-else v-bind:disabled="true" v-on:click="validateAndSubmit" type="button"
                class="btn btn-round btn-info">Create MV Project
            </button>

        </div>

        <el-dialog :visible.sync="showItemsModal" :show-close="false" width="40%" class="newProjectItemModal">
            <species-list v-if="itemModalType === 'species'"></species-list>
            <drug-vaccine-list v-if="itemModalType === 'vaccine'"></drug-vaccine-list>

            <div class="items-footer">
                <button type="button" class="btn btn-default" style="background-color: #eb6532 !important;"
                    data-dismiss="modal" @click="showItemsModal = false">
                    Close
                </button>

            </div>
        </el-dialog>
    </div>

</template>

<script>

import mixin from '../../mixins/GlobalMixin';

import { mapGetters } from 'vuex';

import ImageUpload from '../ImageUpload.vue';

import { Select, Option, CheckboxGroup, Checkbox, Dialog } from 'element-ui';

import { API_KEY } from './Maps/API_KEY';
import GoogleMapsLoader from 'google-maps';
//pradeep
import Swal from 'sweetalert2';
//End
GoogleMapsLoader.KEY = API_KEY;
GoogleMapsLoader.LIBRARIES = ['places'];

import mvlogo from '../../assets/images/mv-logo.png';


import timezoneJson from '../../assets/json/timezones.json'
import SpeciesList from "./SpeciesList";
import DrugVaccineList from "./DrugVaccineList";

export default {
    name: 'NewMvProject',
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        [CheckboxGroup.name]: CheckboxGroup,
        [Checkbox.name]: Checkbox,
        [Dialog.name]: Dialog,
        'image-upload': ImageUpload,
        SpeciesList,
        DrugVaccineList
    },
    mixins: [mixin],
    props: {
        projectId: {
            type: Number,
            default: 0,
        },
        timezones: {
            type: Array,
            default: timezoneJson
        },
        mvlogo: {
            type: String,
            default: mvlogo,
        },
    },
    data() {
        return {
            showItemsModal: false,
            itemModalType: '',
            imageUploadApiUrl: window.API_BASE_URL + '/UploadToS3/upload/project_image',
            ownerships: [
                "Owned",
                "Owned free roaming",
                "Community",
                "Community free roaming",
                "Wild"
            ],
            vaccinationDrug: [
                {
                    id: 1,
                    vaccination_drug: 'Rabies'
                },
            ],
            orgAnimals: [],
            model: {
                projectName: '',
                projectDescription: '',
                selectedAnimals: [],
                dateFormats: ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD'],
                selectedDateFormat: 'DD-MM-YYYY',
                selectedTimezone: 'UTC',
                projectAssociate: '2',
                selectedCategories: [],
                selectedOrganisation: null,
                selectedCountry: null,
                selectedState: null,
                selectedLocality: null,
                selectedLocation: null,
                vaccinationDrug: [],
                surveyPercentage: 70,
                projectBannerMessage: '',
            },
            modelValidations: {
                projectName: {
                    required: true,
                },
                surveyPercentage: {
                    required: true,
                }
            },
            isDisabled: false,
            //uploadURL: 'http://www.hsi.org/assets/images/logos/hsi_global.png',
            uploadURL: '',
            uploadingLabel: 'Optional',
            uploadPending: false,
            googleCountries: [],
            googleStates: [],
            googleLocality: [],
            fetchingAddress: false,
            selectCountryLabel: 'Select Country',
            selectStateLabel: 'Select State',
            selectLocalityLabel: 'Select Locality',

            map: null,
            selectedMarker: null,

        }
    },

    methods: {
        init() {

            let vm = this;

            vm.$store.dispatch('user/getUserOrganisations');


            Bus.$on('example-did-upload', (error, data, response) => {
                if (error) {
                    toastr.error(error);
                } else {
                    vm.uploadURL = response.src;
                    vm.uploadingLabel = "Uploaded";
                    vm.uploadPending = false;
                }
            });

            Bus.$on('example-is-saving', () => {
                vm.uploadPending = true;
                vm.uploadingLabel = "Uploading Image...";
            });

            GoogleMapsLoader.load((google) => {
                vm.initRegularMap(google)
            });

        },
        initRegularMap(google) {
            let vm = this;

            // Regular Map
            const myLatlng = new window.google.maps.LatLng(20.0532212, 64.4407944);
            const mapOptions = {
                zoom: 7,
                center: myLatlng, // we disable de scroll over the map, it is a really annoing when you scroll through page
            };

            vm.map = new window.google.maps.Map(document.getElementById('regularMap'), mapOptions);
            let map = vm.map;
            let card = document.getElementById('pac-card');
            let input = document.getElementById('pac-input');
            let types = document.getElementById('type-selector');

            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(card);

            let autocomplete = new google.maps.places.Autocomplete(input);

            // Bind the map's bounds (viewport) property to the autocomplete object,
            // so that the autocomplete requests use the current map bounds for the
            // bounds option in the request.
            autocomplete.bindTo('bounds', map);

            // Set the data fields to return when the user selects a place.
            autocomplete.setFields(
                ['address_components', 'geometry', 'icon', 'name']);

            autocomplete.addListener('place_changed', function () {
                let place = autocomplete.getPlace();
                if (!place.geometry) {
                    // User entered the name of a Place that was not suggested and
                    // pressed the Enter key, or the Place Details request failed.
                    window.alert("No details available for input: '" + place.name + "'");
                    return;
                }

                // If the place has a geometry, then present it on a map.
                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(17);  // Why 17? Because it looks good.
                }

                let address = '';
                if (place.address_components) {
                    address = [
                        (place.address_components[0] && place.address_components[0].short_name || ''),
                        (place.address_components[1] && place.address_components[1].short_name || ''),
                        (place.address_components[2] && place.address_components[2].short_name || '')
                    ].join(' ');
                }
            });

            // Sets a listener on a radio button to change the filter type on Places
            // Autocomplete.
            function setupClickListener(id, types) {
                let radioButton = document.getElementById(id);
                radioButton.addEventListener('click', function () {
                    autocomplete.setTypes(types);
                });
            }

            setupClickListener('changetype-all', []);
            setupClickListener('changetype-address', ['address']);
            setupClickListener('changetype-establishment', ['establishment']);

            google.maps.event.addListener(map, 'click', function (event) {
                placeMarker(event.latLng);
            });

            if (vm.projectId == 0) {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        let pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };

                        map.setCenter(pos);
                        map.setZoom(15);
                    }, function () {
                        handleLocationError(true);
                    });
                } else {
                    // Browser doesn't support Geolocation
                    handleLocationError(false);
                }
            }


            function handleLocationError(browserHasGeolocation) {
                if (browserHasGeolocation) {
                    //alert('Error: The Geolocation service failed.');
                    console.log('Error: The Geolocation service failed.');
                } else {
                    //alert('Error: Your browser doesn\'t support geolocation.');
                    console.log('Error: Your browser doesn\'t support geolocation.');
                }
            }

            function placeMarker(location) {
                if (vm.selectedMarker && vm.selectedMarker.setMap) {
                    vm.selectedMarker.setMap(null);
                }
                vm.selectedMarker = new google.maps.Marker({
                    position: location,
                    map: map
                });
                let latLng = vm.selectedMarker.getPosition().lat() + ',' + vm.selectedMarker.getPosition().lng();
                vm.getGeoLocation(latLng);
            }

        },
        getGeoLocation: function (latLng) {
            let vm = this;

            vm.selectCountryLabel = 'Loading..';
            vm.selectStateLabel = 'Loading...';
            vm.selectLocalityLabel = 'Loading...';

            vm.model.selectedLocation = latLng;

            vm.googleCountries = [];
            vm.model.selectedCountry = null;

            vm.googleStates = [];
            vm.model.selectedState = null;

            vm.googleLocality = [];
            vm.model.selectedLocality = null;

            vm.fetchingAddress = true;

            delete window.axios.defaults.headers.common['Authorization'];

            /*let instance = axios.create({
             headers: {
             common: {
             'X-CSRF-TOKEN': 'totallyfaketoken',
             },
             },
             });*/

            axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    latlng: latLng,
                    key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
                },
            }).then(response => {
                for (let i = 0; i < Object.keys(response.data.results).length; i++) {
                    for (let j = 0; i < Object.keys(response.data.results[i].address_components).length; j++) {
                        if (response.data.results[i].address_components[j].types.indexOf("country") > -1) {
                            vm.googleCountries.push(response.data.results[i].address_components[j].long_name);
                        }
                        if (response.data.results[i].address_components[j].types.indexOf("administrative_area_level_1") > -1) {
                            vm.googleStates.push(response.data.results[i].address_components[j].long_name);
                        }
                        if (response.data.results[i].address_components[j].types.indexOf("locality") > -1) {
                            vm.googleLocality.push(response.data.results[i].address_components[j].long_name);
                        }
                    }
                }
                if (vm.googleStates.length < 1) {
                    vm.googleStates = vm.googleCountries;
                }
                if (vm.googleLocality.length < 1) {
                    vm.googleLocality = vm.googleStates;
                }

                vm.fetchingAddress = false;

                vm.selectCountryLabel = 'Select Country';
                vm.selectStateLabel = 'Select State';
                vm.selectLocalityLabel = 'Select Locality';

                vm.model.selectedCountry = vm.googleCountries[0];
                vm.model.selectedState = vm.googleStates[0];
                vm.model.selectedLocality = vm.googleLocality[0];

            }).catch(error => {
                console.log(error);

                if (vm.googleStates.length < 1) {
                    vm.googleStates = vm.googleCountries;
                }
                if (vm.googleLocality.length < 1) {
                    vm.googleLocality = vm.googleStates;
                }

                vm.fetchingAddress = false;
                vm.selectCountryLabel = 'Select Country';
                vm.selectStateLabel = 'Select State';
                vm.selectLocalityLabel = 'Select Locality';

                vm.model.selectedCountry = vm.googleCountries[0];
                vm.model.selectedState = vm.googleStates[0];
                vm.model.selectedLocality = vm.googleLocality[0];
            });

            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + vm.$store.getters.getJwt;

        },
        validateAndSubmit: function (event) {

            if (this.model.selectedCategories.length == 0) {
                this.showAlert('Error', "You must select at least one ownership option", false);
                return;
            }

            if (!this.onLine) {
                this.showAlert('Offline', "You are offline please check your network connection and try again", false);
                return;
            }

            if (this.uploadPending) {
                this.showAlert('Error', "Please wait image is being uploaded", false);
                return;
            }
            if (this.model.projectName == '') {
                this.showAlert('Error', "The projectName field is required", false);
                return;
            }
            if (this.model.selectedLocation == null) {
                this.showAlert('Error', "Please select location pin point on map for this project.", false);
                return;
            }
            this.isDisabled = true;

            this.$validator.validateAll().then((result) => {
                let vm = this;
                if (vm.uploadURL != '') {
                    vm.uploadURL = vm.uploadURL;
                } else if (this.model.activeTab == 'MV') {
                    vm.uploadURL = '/images/mv_logo.png';
                }
                if (result) {
                    if (vm.model.selectedAnimals.length === 0) {
                        vm.isDisabled = false;
                        vm.showAlert('Error', "Please select one or more animals for this project.", false);
                        return;
                    } else {
                        if (vm.projectId == 0) {
                            vm.$store.dispatch('user/createProject', {
                                name: vm.model.projectName,
                                description: vm.model.projectDescription,
                                banner_message: vm.model.projectBannerMessage,
                                timezone: vm.model.selectedTimezone,
                                // project_animals: JSON.stringify(vm.model.selectedAnimals),
                                project_animals: vm.model.selectedAnimals,
                                date_format: vm.model.selectedDateFormat,
                                type: 'MV',
                                image_url: vm.uploadURL,
                                project_location_lat_lng: vm.model.selectedLocation,
                                country: vm.model.selectedCountry,
                                google_admin_area_level1: vm.model.selectedState,
                                google_locality: vm.model.selectedLocality,
                                organisation_id: vm.model.selectedOrganisation,
                                vaccination_drug_ids: vm.model.vaccinationDrug,
                                category: vm.model.selectedCategories.toString(),
                                survey_percentage: vm.model.surveyPercentage,
                            }).then((successful) => {
                                if (successful === true) {
                                    this.$store.dispatch('user/getUser').then((response) => {
                                        this.$root.$emit('userDataSet');
                                        vm.$router.replace("/platform/projects");
                                    });
                                } else {
                                    if (successful.length == 0) {
                                        vm.showAlert('Error', ' Sorry, You cannot create this project because a project with same name already exist.', false);
                                    } else {
                                        vm.showAlert('Error', successful.toString(), false);
                                    }
                                }
                            });
                        }
                    }
                }
                vm.isDisabled = false;
                return false;
            }).catch(() => {
                this.isDisabled = false;
                return false
            });
        },
        getError: function getError(fieldName) {
            return this.errors.first(fieldName);
        },
        getOrgVaccinations() {

            const vm = this;
            vm.vaccinationDrug = [];

            if (vm.model.selectedOrganisation != null) {
                const orgVaccinationDrugs = vm.$store.getters.getVaccinationDrugs;
                for (let i = 0; i < orgVaccinationDrugs.length; i++) {
                    let vaccine = {
                        id: orgVaccinationDrugs[i].id,
                        vaccination_drug: orgVaccinationDrugs[i].vaccination_drug_name,
                    };
                    vm.vaccinationDrug.push(vaccine);

                }
            }


        },
        cancleNewProject(type) {
            let vm = this;
            vm.$router.replace("/platform/projects");

            // new Swal({
            //     title: 'Are you sure?',
            //     text: `You won't be able to revert this!`,
            //     type: 'warning',
            //     showCancelButton: true,
            //     confirmButtonClass: 'btn btn-success btn-fill',
            //     cancelButtonClass: 'btn btn-danger btn-fill',
            //     confirmButtonText: 'Yes,cancel it!',
            //     buttonsStyling: false,
            // }).then(function (result) {
            //     if (result.value) {
            //         new Swal({
            //             title: 'Cancelled!',
            //             text: '',
            //             type: 'success',
            //             confirmButtonClass: 'btn btn-success btn-fill',
            //             buttonsStyling: false,
            //             //pradeep
            //         }).then(function (result) {
            //             vm.$router.replace("/platform/projects");
            //         });
            //         //End
            //     }
            // });
        },
        isCharAllow: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
                return true;
            } else {
                evt.preventDefault();
                this.showAlert('Error', "Only alphabets are allowed", false);
            }
        },
        getOrgAnimals() {
            let vm = this;
            vm.orgAnimals = [];
            if (vm.model.selectedOrganisation != null) {
                const orgSpecies = vm.$store.getters.getOrgSpecies;
                for (let i = 0; i < orgSpecies.length; i++) {
                    let vaccine = {
                        id: orgSpecies[i].id,
                        species_name: orgSpecies[i].species_name,
                    };
                    vm.orgAnimals.push(vaccine);
                }
            }
        },

        getOrgAnimalsAndDrugs() {
            this.getOrgAnimals();
            this.getOrgVaccinations();
        },
        onVisibilityChange() {
            const vm = this;
            if (vm.model.projectAssociate === '1') {
                new Swal({
                    icon: 'info',
                    title: 'Are you sure you want to change visibility to Private?',
                    text: `Private projects are only visible to you, you can not invite others to the project. Mainly intended for testing/setup purposes.`,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    cancelButtonClass: 'btn btn-danger btn-fill',
                    confirmButtonText: 'Yes',
                    buttonsStyling: false,
                }).then(function (result) {
                    if (!result.value) {
                        vm.model.projectAssociate = '2';
                    }
                });
            }
        },
        showManageItemsModal(modalType) {
            this.showItemsModal = true;
            this.itemModalType = modalType;
        }

    },

    computed: {
        ...mapGetters('user', ['userOrganisations']),
        projectAssociateInfo: function () {
            let vm = this;
            if (vm.model.projectAssociate == '1') {
                vm.model.selectedOrganisation = null;
                vm.vaccinationDrug = [
                    {
                        id: 1,
                        vaccination_drug: 'Rabies'
                    },
                ];
                vm.model.vaccinationDrug = [];
                return 'Private projects are only visible to you, you can not invite others to the project.  Mainly intended for testing/setup purposes.  You can later change visibility to Organisation if you are affiliated with one.';
            } else {
                vm.vaccinationDrug = [];
                vm.model.vaccinationDrug = [];
                if (vm.model.projectAssociate == '2' && vm.userOrganisations.length > 0) {
                    vm.model.selectedOrganisation = vm.userOrganisations[0].organisation.id

                    vm.getOrgAnimalsAndDrugs()
                    return vm.userOrganisations[0].organisation.name + ' organisation will be notified for the new project and will have full rights to transfer and delete the project.';
                } else {
                    vm.model.selectedOrganisation = null
                    return ''
                }
            }
        },
        constructTitle: function () {
            return "New Project";
            let prefix = this.projectId > 0 ? 'Update ' : 'Create New ';
            if (this.model.projectAssociate == '1') {
                this.model.selectedOrganisation = null;
                return prefix + ' MV Project for Self';
            } else if (this.model.projectAssociate == '2' && this.userOrganisations != null) {
                return prefix + ' MV Project for Organisation';
            }
        },
    },

    mounted() {
        this.init();
    },

    // beforeRouteLeave(to, from, next) {
    //     // If the form is dirty and the user did not confirm leave,
    //     // prevent losing unsaved changes by canceling navigation
    //     const leaveConfirm = window.confirm(
    //         "Are you sure you want to leave? any unsaved changes will be lost"
    //     );
    //     if (leaveConfirm) {
    //         next();
    //     } else {
    //         // Navigate to next view
    //         next(false);
    //     }
    // },


}

</script>
<style scoped>
#description {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
}

#infowindow-content .title {
    font-weight: bold;
}

#infowindow-content {
    display: none;
}

#map #infowindow-content {
    display: inline;
}

.pac-card {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-family: Roboto;
    width: 100%;
    max-width: 424px;
}

.pac-card #pac-input {
    width: 100%;
    max-width: 400px;
}

#pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
}

.pac-controls {
    display: inline-block;
    padding: 5px 11px;
}

.pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
}

#pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
}

#pac-input:focus {
    border-color: #4d90fe;
}

#title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
}

a {
    color: #51cbce;
}

a:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #51cbce;
}

.checkbox_lbl {
    margin-right: 20px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-top: -14px;
}

.hideMvOptions {
    display: none;
}

.logo_img {
    height: 30px;
    border-radius: 0px !important;
}

.hideMvOptions {
    display: none;
}

.logo_img {
    height: 30px;
    border-radius: 0px !important;
}

/* Pradeep Start */
.set_msg {
    margin-top: 5%;
}

.help-icon {
    cursor: pointer;
    position: absolute;
    margin-left: 5px;
    margin-top: -15px;
    color: #51cbce !important;
    ;
}

@media (max-width: 320px) {
    .set_msg {
        margin-top: 15% !important;
    }
}

@media (min-width: 321px) and (max-width: 374px) {
    .set_msg {
        margin-top: 15% !important;
    }
}

@media (min-width: 375px) and (max-width: 424px) {
    .set_msg {
        margin-top: 15% !important;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .set_msg {
        margin-top: 15% !important;
    }
}

@media (min-width: 768px) and (max-width: 769px) {
    .set_msg {
        margin-top: 5% !important;

    }
}

.el-checkbox {
    margin: 0;
    padding: 0px 20px 5px 0px;
}

.el-select {
    width: inherit;
}

.items-footer {
    text-align: right;
}

/* Pradeep End */
</style>
