<template>
    <div>
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div v-if="questionsData.length" class="row setMarginTop set_res_margin_top">
            <div class="col-xs-12 col-sm-12 col-md-12 col-xl-12">
                <div class="row setTitleDiv">
                    <p></p>
                </div>
                <div class="setMarginTop">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-6" v-for="(questionData, index) in questionsData" :key="index">
                            <div class="card setShadow">
                                <div class="card-body" style="height: 435px;">
                                    <highcharts :options="getChartOptions(questionData, chartTypes[index % chartTypes.length])"></highcharts>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '../../../mixins/GlobalMixin';
import VOffline from 'v-offline';
import {Chart} from 'highcharts-vue';
import Highcharts from 'highcharts';
import healthClinicService from "../../../services/health-clinic.service";

export default {
    components: {
        VOffline,
        highcharts: Chart
    },
    mixins: [mixin],
    data() {
        return {
            errorMsg: null,
            callingApi: true,
            questionsData: [],
            chartTypes: ['pie', 'column']
        }
    },
    methods: {
        async getSurveyData() {
            let vm = this;
            const loading = this.$loading({
                lock: true,
                text: 'Please wait loading survey...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            healthClinicService.getSurveyAnalysis()
            .then((apiResposne) => {
                loading.close();
                vm.questionsData = apiResposne.response.data;
            });
        },
        getChartOptions(questionData, chartType) {
            const seriesData = chartType === 'pie'
            ? questionData.options.map(opt => ({ name: opt.option, y: opt.count }))
            : questionData.options.map(opt => opt.count);

            return {
            chart: { type: chartType },
            title: { text: questionData.question },
            xAxis: chartType === 'pie' ? undefined : { 
                categories: questionData.options.map(opt => opt.option),
                title: { text: null }
            },
            yAxis: chartType === 'pie' ? undefined : {
                min: 0,
                title: { text: 'Count' }
            },
            series: [{
                name: 'Responses',
                data: seriesData
            }],
            plotOptions: chartType === 'pie' ? {
                pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
                }
            } : {}
            };
        }

    },
    mounted() {
        let vm = this;
        vm.$store.dispatch("setScreenName", "Survey Analysis");
        vm.getSurveyData();
    }
}
</script>

<style scoped>

</style>
